
import * as netlifyRuntime$NcddXzXFYoa_G2QpucLhMMa6JAbk843I4fCqgSiMge0 from '/opt/build/repo/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/netlifyImageCdn'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "netlify",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['netlify']: { provider: netlifyRuntime$NcddXzXFYoa_G2QpucLhMMa6JAbk843I4fCqgSiMge0, defaults: {} }
}
        