<script setup>
const props = defineProps({
  media: Object,
  sizes: { type: Array, default: [100, 100] },
  observe: { type: Boolean, default: false },
  loading: {
    type: String,
    default: 'lazy',
  },
  withPlaceholder: { type: Boolean, default: true },
  bigBorders: Boolean,
  mayAutoplay: { type: Boolean, default: true },
  withFilter: Boolean,
  isRounded: Boolean,
})
const isMediaSection = inject('isMediaSection', false)
</script>

<template>
  <div class="media" v-if="media?.type === 'svg'" v-html="media.code"></div>
  <div
    class="media relative"
    v-else-if="media"
    :class="{
      'overflow-hidden rounded-corner-02 lg:rounded-corner-02-desktop': isRounded,
    }"
  >
    <component-behavior-in-view :once="true" :noop="!observe">
      <component-element-image
        v-if="media?.type === 'image'"
        :media="media"
        :sizes="sizes"
        :loading="loading"
        :class="{ observed: observe }"
        :with-placeholder="withPlaceholder"
      />
      <component-element-video
        v-if="media?.type === 'video'"
        :media="media"
        :loading="loading"
        :class="{ observed: observe }"
        :may-autoplay="mayAutoplay"
      />
    </component-behavior-in-view>
  </div>
</template>

<style lang="postcss">
:where(.media) {
  position: relative;
}

.media {
  .loading-effect {
    position: absolute;
    inset: var(--spacing-3);
    color: var(--cream);

    opacity: 0;

    svg {
      fill: transparent;
    }
  }

  .observer {
    position: relative;
    background: var(--black);
    overflow: clip;

    img,
    video {
      transform: scale(1.2);
      /* clip-path: inset(10% 10% 10% 10%); */
    }

    &.is-seen {
      img,
      video {
        transition:
          clip-path 2s var(--ease-in-out) 0.1s,
          transform 2s var(--ease-in-out) 0.1s;
        /* clip-path: inset(px(-1) px(-1) px(-1) px(-1)); */
        transform: scale(1);
      }

      .loading-effect {
        transition: opacity 2s var(--ease-in-out);

        opacity: 1;
      }
    }

    &.in-view {
    }
  }

  .caption {
    color: var(--transparent-caption);
  }

  :where(img),
  :where(video) {
    object-fit: cover;
    height: 100%;
    width: 100%;
    vertical-align: middle;
  }

  svg {
    fill: currentColor;
    height: 100%;
    width: 100%;

    path {
      fill: inherit;
    }
  }
}
</style>
