<script setup>
/**
 * note: shouldn't be used directly, instead use the CommonMedia:
 * <CommonMedia :media="myImageOrVideo" />
 */

const props = defineProps({
  media: Object,
  loading: String, // used for overwriting the "loading" attr from cms
  // sizes: Array,
  mayAutoplay: Boolean,
})

const focalPoint = computed(() => {
  if (!props.media.focalPoint) return null

  const { x, y } = props.media.focalPoint

  return {
    objectPosition: `${x * 100}% ${y * 100}%`,
  }
})

// const sizesAttribute = computed(() => {
//   const [mobileSize, desktopSize] = props.sizes
//   return `(max-width: 900px) ${mobileSize}vw, ${desktopSize}vw`
// })

const muxOrCraftUrl = computed(() => props.media.muxMp4Url || props.media.attributes.src)
const showControls = inject('show-video-controls', false)

const video = ref(null)
const { isFullscreen } = useFullscreen(video)
</script>

<template>
  <video
    ref="video"
    :style="focalPoint"
    v-bind="{
      ...media.attributes,
      src: muxOrCraftUrl,
      autoplay: !mayAutoplay ? false : media.attributes.autoplay,
    }"
    :loading="loading || media.attributes.loading"
    :poster="media.muxImageUrl"
    :controls="showControls"
    :class="{
      'is-fullscreen': isFullscreen,
    }"
  />
</template>

<style lang="postcss" scoped>
video {
  &.is-fullscreen {
    object-fit: contain;
  }
}
</style>
