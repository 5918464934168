<script setup>
const emit = defineEmits(["in-view"]);

const props = defineProps({
  rootMargin: { type: String, default: "0px" },
  once: Boolean,
  noop: Boolean,
});

const element = ref(null);

const isSeen = ref(false);
const inView = ref(false);

const options = {
  threshold: 0,
  rootMargin: props.rootMargin,
};

const { stop } = useIntersectionObserver(
  element,
  ([{ isIntersecting }]) => {
    if (!props.once) {
      inView.value = isIntersecting;
      emit("in-view", isIntersecting);
    }

    if (!isIntersecting) return;

    isSeen.value = true;

    if (props.once) stop();
  },
  options,
);

onUnmounted(stop);
</script>

<template>
  <slot v-if="noop" />

  <div
    class="observer"
    ref="element"
    :class="{ 'is-seen': isSeen, 'in-view': inView }"
    v-else
  >
    <slot />
  </div>
</template>

<style lang="postcss">
.observer {
  height: 100%;
  width: 100%;
}
</style>
