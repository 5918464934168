<script setup>
const props = defineProps({
  rte: String,
})

// Define Tailwind Typography classes dynamically
const classes = {
  h1: 'prose-h1:font-text prose-h1:text-h2 xl:prose-h1:text-h2Desktop prose-h1:ultra:text-h2Ultra prose-h1:font-normal prose-h1:break-words prose-h1:text-[var(--text-theme)]',
  h2: 'prose-h2:font-text prose-h2:text-h3 xl:prose-h2:text-h3Desktop prose-h2:font-normal  prose-h2:break-words prose-h2:text-[var(--text-theme)]',
  h3: 'prose-h3:font-text prose-h3:text-h4 xl:prose-h3:text-h4Desktop prose-h3:font-normal prose-h3:break-words prose-h3:text-[var(--text-theme)]',
  h4: 'prose-h4:font-text prose-h4:text-h4 xl:prose-h4:text-h4Desktop prose-h4:font-normal  prose-h4:text-[var(--text-theme)]',
  body: 'prose-p:font-text prose-p:text-body xl:prose-p:text-bodyDesktop prose-p:font-normal prose-p:whitespace-pre-line prose-p:space-y-4  prose-p:text-[var(--text-theme)]',
  strong:
    'prose-strong:font-text prose-strong:text-body xl:prose-strong:text-bodyDesktop prose-strong:font-bold prose-strong:whitespace-pre-line prose-strong:space-y-4  prose-strong:text-[var(--text-theme)]',
  i: 'prose-i:font-text prose-i:text-body xl:prose-i:text-bodyDesktop prose-i:font-bold prose-i:whitespace-pre-line prose-i:space-y-4  prose-i:text-[var(--text-theme)]',
  a: 'prose-a:font-text prose-a:text-body xl:prose-a:text-bodyDesktop prose-a:font-normal prose-a:whitespace-pre-line prose-a:space-y-4  prose-a:text-[var(--text-theme)]',
  list: 'prose-ul:font-text prose-ul:text-body xl:prose-ul:text-bodyDesktop prose-ul:font-normal prose-ul:whitespace-pre-line prose-ul:space-y-4  prose-ul:text-[var(--text-theme)]',
  listItem:
    'prose-li:font-text prose-li:marker:text-[var(--text-theme)] prose-li:text-body xl:prose-li:text-bodyDesktop prose-li:font-normal prose-li:whitespace-pre-line prose-li:space-y-4  prose-li:text-[var(--text-theme)]',
}

const allClasses = Object.values(classes).join(' ')
</script>

<template>
  <div class="rte prose" v-html="rte" :class="allClasses"></div>
</template>
